import {
  ref,
  defineComponent,
  onMounted,
  onActivated,
} from "vue";
import {
  CoreDayByExaminerDto,
  AvailabilitySearchListRequest,
  ExportAvailabilitySearchListRequest,
  FileResponse,
  ExportAvailabilitySummaryRequest,
} from "@/api-client/client";
import { _Client } from "@/api-client";
import { Form } from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import { useRouter } from "vue-router";
import { ExportXlsx } from "@/utils/common";
import AvailabilityLog from './AvailabilityLog/index.vue'
type RangeValue = [Dayjs, Dayjs];
const useForm = Form.useForm;

const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth() + 1;

export default defineComponent({
  components: {
    AvailabilityLog
  },
  setup() {
    const exportAvailabilitySummaryTimeValue = ref<RangeValue>();
    const exportAvailabilitySummaryTimehackValue = ref<RangeValue>();

    const logTimeValue = ref<RangeValue>();
    const logTimeHackValue = ref<RangeValue>();


    const disabledDate = (current: Dayjs) => {
      if (!exportAvailabilitySummaryTimeValue.value || (exportAvailabilitySummaryTimeValue.value as any).length === 0) {
        return false;
      }
      const tooLate = exportAvailabilitySummaryTimeValue.value[0] && current.diff(exportAvailabilitySummaryTimeValue.value[0], 'days') > 30;
      const tooEarly = exportAvailabilitySummaryTimeValue.value[1] && exportAvailabilitySummaryTimeValue.value[1].diff(current, 'days') > 30;
      return tooEarly || tooLate;
    };
    const logTimeDisabledDate = (current: Dayjs) => {
      if (!logTimeValue.value || (logTimeValue.value as any).length === 0) {
        return false;
      }
      const tooLate = logTimeValue.value[0] && current.diff(logTimeValue.value[0], 'days') > 30;
      const tooEarly = logTimeValue.value[1] && logTimeValue.value[1].diff(current, 'days') > 30;
      return tooEarly || tooLate;
    };

    const onOpenChange = (open: boolean) => {
      if (open) {
        exportAvailabilitySummaryTimeValue.value = [] as any;
        exportAvailabilitySummaryTimehackValue.value = [] as any;
      } else {
        exportAvailabilitySummaryTimehackValue.value = undefined;
      }
    };
    const logTimeOnOpenChange = (open: boolean) => {
      if (open) {
        logTimeValue.value = [] as any;
        logTimeHackValue.value = [] as any;
      } else {
        logTimeHackValue.value = undefined;
      }
    };

    const onChange = (val: RangeValue) => {
      exportAvailabilitySummaryTimeValue.value = val;
    };
    const logTimeOnChange = (val: RangeValue) => {
      logTimeValue.value = val;
    };

    const onCalendarChange = (val: RangeValue) => {
      exportAvailabilitySummaryTimeValue.value = val;
    };
    const logTimeOnCalendarChange = (val: RangeValue) => {
      logTimeValue.value = val;
    };

    const columns = [
      {
        title: 'Date',
        dataIndex: 'dateStr',
      },
      {
        title: 'WeekDay',
        dataIndex: 'weekDay'
      },
      {
        title: 'Availability',
        dataIndex: 'avilabilityCount'
      },
      {
        title: 'Core Day',
        dataIndex: 'coreDayCount'
      },
      {
        title: 'Action',
        dataIndex: 'action',
        fixed: 'right',
        width: 200
      }
    ]
    const { push } = useRouter();
    const tableLoading = ref<boolean>(false);
    const searchParames = ref<AvailabilitySearchListRequest>();
    const tableSource = ref<CoreDayByExaminerDto[]>()
    const searchYear = ref<number>(currentYear);
    const searchMonth = ref<number>(currentMonth);
    const exportSearchListLoading = ref<boolean>(false);
    const exportAvailabilitySummaryLoading = ref<boolean>(false);
    const exportLogLoading = ref<boolean>(false);
    const examinerNoStr = ref<string>();
    const isShowLogModal = ref<Boolean>(false);
    async function getList() {
      try {
        tableLoading.value = true
        searchParames.value = new AvailabilitySearchListRequest();
        searchParames.value.date = new Date(searchYear.value + '-' + (searchMonth.value).toString().padStart(2, '0') + '-01');
        tableSource.value = await _Client.availabilityManagementClient.searchList(searchParames.value!)
      }
      finally {
        tableLoading.value = false
      }
    }
    async function ExportSearchList() {
      try {
        exportSearchListLoading.value = true
        let excelStream: FileResponse | null = null
        let params = new ExportAvailabilitySearchListRequest();
        params.date = searchParames.value!.date;
        excelStream = await _Client.availabilityManagementClient.exportSearchList(params)
        ExportXlsx(excelStream, 'Examiner Availability_' + searchParames.value!.date.getFullYear() + '-' + (searchParames.value!.date.getMonth() + 1).toString().padStart(2, '0') + '.xlsx')
      } finally {
        exportSearchListLoading.value = false
      }
    }

    async function ExportAvailabilitySummary() {
      try {
        exportAvailabilitySummaryLoading.value = true
        let excelStream: FileResponse | null = null
        let params = new ExportAvailabilitySummaryRequest();
        params.startTime = new Date(exportAvailabilitySummaryTimeValue.value![0].toISOString().split('T')[0]);
        params.endTime = new Date(exportAvailabilitySummaryTimeValue.value![1].toISOString().split('T')[0]);
        excelStream = await _Client.availabilityManagementClient.exportAvailabilitySummary(params);
        let startTimeStr = params.startTime.getFullYear() + (params.startTime.getMonth() + 1).toString().padStart(2, '0') + (params.startTime.getDate()).toString().padStart(2, '0')
        let endTimeStr = params.endTime.getFullYear() + (params.endTime.getMonth() + 1).toString().padStart(2, '0') + (params.endTime.getDate()).toString().padStart(2, '0')
        ExportXlsx(excelStream, 'Availability Summary ' + startTimeStr + '-' + endTimeStr + '.xlsx')
      } finally {
        exportAvailabilitySummaryLoading.value = false
      }
    }


    const changeSearchParames = async () => {
      await getList()
    };
    async function resetSearchParams() {
      searchYear.value = currentYear;
      searchMonth.value = currentMonth;

      await getList()
    }
    async function goToModifyPage(dataItem: DataItem) {

      let goToRouter = `/availabilityManagement/modifyAvailability/${dataItem.date}`;
      push(goToRouter)
    }

    onMounted(async () => {
      await getList()
    })

    onActivated(() => {
      getList();
    });

    async function showLogModal() {
      //let hasEditPermission = await checkAccess(`${internalInstance!.appContext.config.globalProperties.$pageName}:${internalInstance!.appContext.config.globalProperties.$actionNames.Edit}`, examinerHub!.officeId)
      //if (!hasEditPermission) { return }
      isShowLogModal.value = true
    }

    async function closeLogModal(refresh: boolean = false) {
      isShowLogModal.value = false
    }
    return {
      examinerNoStr,
      tableLoading,
      columns,
      tableSource,
      resetSearchParams,
      changeSearchParames,
      goToModifyPage,
      currentYear,
      searchYear,
      searchMonth,
      exportSearchListLoading,
      exportAvailabilitySummaryLoading,
      ExportSearchList,
      exportLogLoading,
      value1: ref<RangeValue>(),
      exportAvailabilitySummaryTimeValue,
      exportAvailabilitySummaryTimehackValue,
      logTimeHackValue,
      disabledDate,
      logTimeDisabledDate,
      onOpenChange,
      onChange,
      onCalendarChange,
      ExportAvailabilitySummary,
      closeLogModal,
      showLogModal,
      isShowLogModal,
      logTimeValue,
      logTimeOnChange,
      logTimeOnOpenChange,
      logTimeOnCalendarChange,
    };
  }
});
