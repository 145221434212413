import { resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "Log Detail",
    visible: true,
    width: "75%",
    onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close(false))),
    footer: []
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        loading: _ctx.tableLoading,
        class: "planning_table",
        size: "middle",
        bordered: "",
        pagination: false,
        columns: _ctx.columns,
        "data-source": _ctx.tableSource,
        scroll: { x: 1000, y: 400 },
        rowKey: (r) => r.venueId
      }, {
        bodyCell: _withCtx(({ column, text, record }) => []),
        _: 1
      }, 8, ["loading", "columns", "data-source", "rowKey"])
    ]),
    _: 1
  }))
}