
import { customRef, ref, defineComponent, onMounted } from "vue";
import Pagination from '@/components/Pagination/index.vue'
import { AvailabilityLogRequest, AvailabilityLogResponse } from "@/api-client/client";
import { _Client } from "@/api-client";

export default defineComponent({
  components: {
    Pagination
  },
  props: {
    startTime: { type: Date },
    endTime: { type: Date },
    examinerNoStr: { type: String },
  },
  setup(props, context) {
    const columns = [
      {
        title: 'Examiner Number',
        dataIndex: 'examinerNumber',
        width: '13%',
        customCell: (record: any, index: any) => {
          // 如果是和上一行一样rowSpan就是0
          if (index > 0 && record.examinerNumber === tableSource.value[index - 1].examinerNumber) {
            return { rowSpan: 0 };
          }
          // 如果是和上一行不一样rowSpan就是rowspan
          const rowspan = tableSource.value.filter((item: any) => item.examinerNumber === record.examinerNumber).length;
          return { rowSpan: rowspan };
        },
      },
      {
        title: 'Examining Date',
        dataIndex: 'examiningDate',
        width: '10%',
      },
      {
        title: 'CreateBy',
        dataIndex: 'createBy',
        width: '10%',
      },
      {
        title: 'CreateOn',
        dataIndex: 'createOnStr',
        width: '15%',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        width: '15%',
      },
    ];
    const tableSource = ref<AvailabilityLogResponse[]>([])
    const tableLoading = ref<boolean>(false)
    /// 获取列表方法
    async function getList() {
      tableSource.value = [];
      try {
        tableLoading.value = true
        let request = new AvailabilityLogRequest();
        request.examinerNoStr = props.examinerNoStr!;
        request.startTime = new Date(props.startTime!.toISOString().split('T')[0]);
        request.endTime = new Date(props.endTime!.toISOString().split('T')[0]);
        tableSource.value = await _Client.availabilityManagementClient.availabilityLogs(request);
      }
      finally {
        tableLoading.value = false
      }
    }
    function close(refresh: boolean = false) {
      context.emit("close", refresh);
    }
    onMounted(async () => {
      await getList()
    })
    return {
      tableSource,
      tableLoading,
      columns,
      close

    };
  },
})
